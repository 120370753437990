/* Modal Overlay */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Background overlay color */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Make sure the modal is on top of everything */
    padding: 1rem;
  }
  
  /* Modal Content */
  .modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 4px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    z-index: 1001; /* Make sure the modal content is on top of the overlay */
  }
  
  /* Modal Close Button */
  .modal-close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }
  