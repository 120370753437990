@import url('https://fonts.googleapis.com/css?family=Open+Sans&display=swap');

.loginCont {
  font-family: 'Open Sans', sans-serif;
  background: #f9faff;
  color: #3a3c47;
  line-height: 1.6;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;
  padding: 0;
  border: 1px solid red;
}

.loginCont h1 {
}

.loginForm {
  background: #fff;
  width: 100%;
  padding: 0% 10% 10% 10%;
  border: 1px solid #e1e2f0;
  border-radius: 4px;
  box-shadow: 0 0 5px 0 rgba(42, 45, 48, 0.12);
  transition: all 0.3s ease;
}

.loginRow {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.loginRow label {
  font-size: 13px;
  color: #8086a9;
}

.loginRow input {
  flex: 1;
  padding: 13px;
  border: 1px solid #d6d8e6;
  border-radius: 4px;
  font-size: 16px;
  transition: all 0.2s ease-out;
}

.loginRow input:focus {
  outline: none;
  box-shadow: inset 2px 2px 5px 0 rgba(42, 45, 48, 0.12);
}

.loginRow input::placeholder {
  color: #C8CDDF;
}

.loginButton {
  width: 100%;
  padding: 12px;
  font-size: 18px;
  background: #3470e4;
  color: #fff;
  border: none;
  border-radius: 100px;
  cursor: pointer;
  font-family: 'Open Sans', sans-serif;
  margin-top: 15px;
  transition: background 0.2s ease-out;
}

.loginButton:hover {
  background:#5286ec;;
}

@media(max-width: 458px) {
  
  /* body {
    margin: 0 18px;
  } */
  
  .loginForm {
    background: #f9faff;
    border: none;
    box-shadow: none;
    padding: 20px 0;
  }

}