/* MultiStepReg.css */

.multistepContainer {
    max-width: 90%;
    margin: 5% auto;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    background-color: #fff;
  }

  .progressBox {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin: 20px auto;
    padding: 10px 10px;
    width:88%;
    border: 1px solid #ccc;

  }
  .progressTitle {
    display: flex;
    text-align: center;
    margin: auto;
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  .progressContainer {
    display: flex;
    align-items: center;
    width: 70%;
    margin: auto;
  }
  
  .progressBar {
    display: flex;
    flex-grow: 1;
    justify-content: space-between;
  }
  
  .progress-item {
    flex-grow: 1;
    padding: 10px;
    text-align: center;
    font-weight: bold;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .completed {
    background-color: #4caf50;
    color: white;
  }
  
  .circle {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #ccc;
    margin: 0 10px;
    padding:10px;
    text-align: center;
  }
  
  .active {
    background-color: #4caf50; /* Change to your desired active color */
  }
  .active svg {
    fill:#ffff
  }
  .line {
    flex: 1;
    height: 1px;
    background-color: #ccc;
  }
  

  /* Responsive Adjustments */
  @media (max-width: 600px) {
    /* .progress-container {
      flex-direction: column;
      align-items: flex-start;
    } */
  
    .progressBar {
      width: 100%;
    }
  
    .progress-item {
      width: 100%;
      margin-bottom: 10px;
    }
  }
  