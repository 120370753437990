.news-heading{
    margin-left: 1rem;
    padding: 0.5rem;
}
.content-main-box{
    /* border: 1px solid red; */
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;
}
.content-box-1{
    width: 63%;
}
.content-news{
    /* border: 1px solid green; */
    width: 100%;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    padding: 0.5rem;
}
.content-news>h2,.content-news > p{
    padding-left: 1rem;
}
.multi-crops{
    width: 100%;
    margin: 0;
    padding: 0;
}


/* CSS for screens between 100px and 550px (mobiles) */
@media (min-width: 100px) and (max-width: 550px) {
    .content-main-box {
        display: flex;
        flex-direction: column;
        
    }
    .content-box-1{
        width: 95%;
    }
  }

/* CSS for screens between 551px and 1024px (tablets) */
@media (min-width: 551px) and (max-width: 1024px) {
   
    .content-main-box {
        display: flex;
        flex-direction: column;
    }
    .content-box-1{
        width: 95%;
    }
  }
