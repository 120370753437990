.categoryManagementContainer {
  /* border: 1px solid red; */
  margin: auto;
  width: 50%;
  padding: 1rem;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 10px;
  padding-bottom: 2rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.categoryManagementContainer > h1 {
  text-align: center;
  margin-bottom: 2rem;
}
.categoryManagementForm {
  border: 1px solid gray;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
}
.categoryManagementForm > input {
  width: 100%;
  padding: 0.5rem;
  border-radius: 5px;
}
#category_submit-btn {
  width: 30%;
  margin: auto;
  background-color: aqua;
  border: 1px solid aqua;
  border-radius: 5px;
  padding: 5px;
}
.category-table-container {
  /* border: 1px solid red; */
  padding: 1rem;
}
.table-box {
  margin: auto;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.category-actions {
  width: 20%;
  text-align: center;
}
.category-actions-btn {
  background-color: aqua;
  margin-right: 1rem;
  padding: 0 1rem;
  border: 1px solid aqua;
  border-radius: 5px;
}
.category-actions-btn:hover{
  background-color: aqua;
}
