@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,400i,700");

.mainFeature{
    width: 90%;
  margin: auto;
}

.featureContainer {
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(3, minmax(0, 1fr)) ;
  margin-bottom: 100px;
}

.mainFeature h2 {
    font-weight: bold;
    font-size: 26px;
    line-height: 38px;
    margin: 22px 0;
    color: #133B5A;
}
.featureItem{
  border: 1px solid grey;
  border-radius: 10px;
  text-align: center;
  background-color: #fff;
}

.featureIcon {
    font-size: 50px;
    margin: 10px;
  }

.featureItem:hover {
    transform: scale(1.04);
}  