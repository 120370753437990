@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,400i,700");

.title{
  font-weight: bold;
  font-size: 26px;
  line-height: 38px;
  margin: 22px 0;
  color: #133B5A;
  width: 90%;
  margin: auto;
}
.subPlans {
  font-family: "Open Sans", sans-serif;
  display: flex;
  /* height: 100vh; */
}
.container {
  margin: auto;
  display: grid;
  gap: 1rem;
  width: 90%;
  max-width: 1000px;
  padding: 2rem;
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

.pricecard {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  border: 1px solid grey;
  border-radius: 10px;
}

.months {
  font-weight: 600;
  font-size:30px;
  margin-bottom: 0.5rem;
  text-align: center;
}

.pricecard .sub {
  line-height: 1.25;
}


.pricecard .headtop {
  background-image: conic-gradient(
    at center top,
    rgb(134, 239, 172),
    rgb(59, 130, 246),
    rgb(147, 51, 234)
  );
}



.pricecard .headtop {
  /* padding: 1.5rem;
  border-radius: 1rem;
  margin-bottom: 0.5rem; */
  /* height: 100px; */
  display: flex;
  flex-direction: column;
  transition: all 0.25s ease-in-out;
  color: #ffffff;
  border: 1px solid #ffffff;
  cursor: pointer;
  border-radius: 10px 10px 0px 0px;
}

.pricecard .headtop:hover {
  transform: scale(1.04);
  box-shadow: 0px 5px 7px -6px rgba(0, 0, 0, 0.08),
    0px 8px 13.5px -6px rgba(0, 0, 0, 0.12),
    0px 16px 36px -6px rgba(0, 0, 0, 0.15);
}

.pricecard .price {
  font-weight: 600;
  font-size: 3rem;
  margin-top: auto;
  line-height: 0.85;
}

.pricecard .details {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  color: #444444;
}

.pricecard .details .rbox {
  display: flex;
  gap: 1rem;
  align-items: center;
  padding-left: 15px;
  border-bottom: 1px solid #ececec;
  transition: all 0.2s ease-in-out;
}

.pricecard .details .rbox:hover {
  gap: 1.25rem;
}

.pricecard .details .rbox:last-of-type {
  border-bottom: none;
}

.pricecard .details .rbox svg {
  width: 0.75rem;
  height: 0.75rem;
  fill: #444444;
}

.subscribe {
  all: unset;
  width: 100%;
  text-align: center;
  background-color: #222222;
  color: #ffffff;
  display: inline-block;
  border-radius: 0.5rem;
  padding: 1rem 0;
  cursor: pointer;
  margin-top: auto;
  transition: all 0.25s ease-in-out;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 0.875rem;
  letter-spacing: 0.75px;
}

button:hover {
  transform: scale(1.04);
  background-color: #333333;
  box-shadow: 0px 5px 7px -6px rgba(0, 0, 0, 0.08),
    0px 8px 13.5px -6px rgba(0, 0, 0, 0.12),
    0px 16px 36px -6px rgba(0, 0, 0, 0.15);
}

@media screen and (max-width:540px) {
  .container {
    margin: auto;
    display: block;
    gap: 1rem;
    width: 90%;
    max-width: 1000px;
    padding: 2rem;
  }
}