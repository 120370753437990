.header-part{
  /* border: 1px solid red; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem;
}
.back-btn{
  background-color: aqua;
  padding: 0 1rem;
  border: 1px solid aqua;
  border-radius: 5px;
}
.back-btn:hover{
  background-color: aqua;
}
.uploaded-news-main-container {
  /* border: 1px solid red; */
  display: flex;
  justify-content: space-between;
  padding: 1rem;
}
.left-side-box {
  /* border: 1px solid black; */
  width: 25%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  overflow-y: auto;
  max-height: 740px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px, rgb(51, 51, 51) 0px 0px 0px 3px;
  padding: 0.5rem;
}
.left-side-box > div {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  width: 100%;
  padding: 5px;
  border-radius: 5px;
}
.left-side-box > div > p {
  text-align: center;
  margin-top: 0.5rem;
  margin-bottom: 0;
}
.right-side-box {
  /* border: 1px solid black; */
  width: 72%;
}
.right-side-box>div{
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px, rgb(51, 51, 51) 0px 0px 0px 3px;
    padding-bottom: 0.5rem;
}
