#editSection-mainContainer {
  /* border: 1px solid red; */
  padding: 1rem;
}
.edit-section-header {
  /* border: 1px solid red; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
}
.edit-section-back-btn {
  background-color: aqua;
  padding: 0 1rem;
  border: 1px solid aqua;
  border-radius: 5px;
}
.edit-section-back-btn:hover{
    background-color: aqua;
}
.form-box {
  width: 50%;
  margin: auto;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding: 1rem;
  border-radius: 10px;
}
