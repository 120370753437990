.table-main-container {
  padding: 1rem;
  /* border: 1px solid black; */
}
.header-box {
  /* border: 1px solid red; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.search-container {
  width: 400px;
  /* border: 1px solid black; */
}
.search-container > input {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  padding: 10px;
  width: 82%;
}
.search-container > button {
  background-color: aqua;
  padding: 10px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}
.table-box {
  border-collapse: collapse;
  width: 100%;
}

th,
td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

th {
  background-color: #f2f2f2;
}

tr:hover {
  background-color: #f5f5f5;
}
.actions {
  padding-left: 1rem;
  width: 20%;
}
.actions-btn {
  background-color: aqua;
  margin: auto;
  margin-right: 1rem;
  padding: 0 1rem;
  border: 1px solid aqua;
  border-radius: 5px;
}
.actions-btn:hover {
  background-color: aqua;
}

.pagination-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.pagination {
  list-style: none;
  display: flex;
  gap: 10px;
}

.pagination li {
  cursor: pointer;
  padding: 5px 10px;
  border: 1px solid #ccc;
}

.pagination li.active {
  background-color: #007bff;
  color: #fff;
  border: 1px solid #007bff;
}
