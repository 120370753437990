@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,400i,700");

.firstCont{
    margin: auto;
    display: grid;
    gap: 1rem;
    max-width: 1000px;
    padding: 2rem;
    grid-template-columns: repeat(2, minmax(0, 1fr));
}

.advertise {
    width:90%;
    margin:auto
}
.advertise img {
    width: 80%;
}
.description {
    width: 90%;
    margin: 20px auto;
    padding: 10px;
    background-color: #ffff;
    box-shadow: 0 0 5px 0 rgba(42, 45, 48, 0.12);
    border-radius: 10px;
}

.description h2 {
    font-weight: bold;
    font-size: 26px;
    line-height: 38px;
    margin: 22px 0;
    color: #133B5A;
}


@media(max-width:900px) {
    .firstCont{
        display: block;
    }
}

