/* Default CSS for desktop */
#calendar-container {
  border: 1px solid lightgray;
  width: 18vw;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 5px;
  border-radius: 8px;
}
.latest-news-heading {
  /* border: 1px solid black; */
  display: flex;
  gap: 0.5rem;
  align-items: center;
  text-align: center;
  padding: 5px 0px 5px 1rem;
  border-bottom: 1px solid lightgray;
}
#icon {
  width: 30px;
  height: 25px;
  /* border: 1px solid red; */
}

.calendar {
  /* border: 1px solid red; */
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.calendar > button {
  border: 1px solid lightgrey;
  color: rgb(75, 141, 204);
  background-color: white;
  font-size: 13px;
}

/* CSS for screens between 100px and 550px (mobiles) */
@media (min-width: 100px) and (max-width: 550px) {
  #calendar-container {
    width: 95vw;
    margin-top: 2rem;
  }
}

/* CSS for screens between 551px and 1024px (tablets) */
@media (min-width: 551px) and (max-width: 1024px) {
  #calendar-container {
    width: 75vw;
    margin: auto;
    margin-top: 2rem;
  }
}
