/* Container for the form */
.registerCont {
    display: flex;
    justify-content: space-between;
    max-width: 800px; /* Adjust as needed */
    margin: 0 auto;
  }
  
  /* Form styling */
  .registerForm {
    width: 100%;
  }
  
  /* Label and input styling */
  .registerForm label {
    display: block;
    margin-bottom: 8px;
    font-weight: bold;
  }
  
  .registerForm input[type="text"],
  select {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
    margin-bottom: 12px;
  }
  
  /* Error message styling */
  .registerForm p {
    color: red;
    margin-top: 4px;
    font-size: 12px;
  }
  
  /* Submit button styling */
  .registerForm input[type="submit"] {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .registerForm input[type="submit"]:hover {
    background-color: #0056b3;
  }
  