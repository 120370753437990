/* Default CSS for desktop */
#bookmark-container {
  border: 1px solid lightgray;
  width: 18vw;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 8px;
  /* margin-top: 2px; */
}
.bookmark-heading {
  /* border: 1px solid black; */
  display: flex;
  gap: 0.5rem;
  align-items: center;
  text-align: center;
  padding: 5px 0px 5px 1rem;
  /* padding-top:5px ; */
  border-bottom: 1px solid lightgray;
}
#icon {
  width: 30px;
  height: 25px;
  /* border: 1px solid red; */
}
#bookmarked-data {
  padding: 15px;
}
#bookmarked-data > div {
  width: 65px;
  height: 125px;
  border: 1px solid black;
}
#view-button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1.3rem;
}
#view-button > button {
  background-color: rgb(93, 144, 147);
  color: white;
  padding: 5px 2.5rem;
  border: none;
  border-radius: 5px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

/* CSS for screens between 100px and 550px (mobiles) */
@media (min-width: 100px) and (max-width: 550px) {
  #bookmark-container {
    width: 95vw;
    margin-bottom: 2rem;
  }
}

/* CSS for screens between 551px and 1024px (tablets) */
@media (min-width: 551px) and (max-width: 1024px) {
  #bookmark-container {
    width: 75vw;
    margin: auto;
    margin-bottom: 2rem;
  }
}
