@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,400i,700");
 
  body {
    background-color:#f8f9fa;
  }
  
  .pages {
    color: #316685;
    text-align: center;
    font-size: calc(1.5rem + 2vw);
    margin-top: 10%;
    
   
  }
  
  .navbar {
    background-color: #fff;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    position: sticky;
    top: 0;
    z-index: 20;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,.2);
  }
  
  .navContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 80px;
    max-width: 1500px;
  }
  
  .mainContainer{
    position:absolute;
    top:0;
    bottom:0;
    left:0;
    right:0;
    background-color:rgba(0,0,0,0.3);
  }
  
  .navLogo {
    margin-left: 20px;
    cursor: pointer;
    flex-grow: 1;
  }
  .navLogo img{
    width:200px;
  }
  .navMenu {
    display: flex;
    text-align: center;
    margin-right: 2rem;
  }
  
  .nav-links {
    color: #fff;
    text-decoration: none;
    padding: 0.5rem 1rem;
    height: 100%;
    border-bottom: 3px solid transparent;
  }
  .fa-code {
    margin-left: 1rem;
  }
  
  .navItem {
    margin-right: 1rem;
  }

  .register {
    background-color: #133B5A;
    border: none;
    width: 70x;
    height: 40px;
    color: #fff;
    font-size: 16px;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;padding: 5px 15px;
    font-size: 14px;
    font-weight: 700;
    font-family: Fira Sans,sans-serif!important;
    border-radius: 10px;
    margin: 0 5px;
    color: #fff;
  }
  .renew {
    background-color: #ffdd40;
    border: none;
    width: 70x;
    height: 40px;
    color: #fff;
    font-size: 16px;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;padding: 5px 15px;
    font-size: 14px;
    font-weight: 700;
    font-family: Fira Sans,sans-serif!important;
    border-radius: 10px;
    margin: 0 5px;
    color: #fff;
  }
  .nav-item:after {
    content: "";
    display: block;
    height: 3px;
    width: 0;
    background: transparent;
    transition: width 0.7s ease, background-color 0.5s ease;
  }
  
  .nav-item:hover:after {
    width: 100%;
    background: #ffdd40;
  }
  
  .nav-item.active {
    color: #ffdd40;
    border: 1px solid #ffdd40;
  }
  
  .nav-icon {
    display: none;
  }
  
   