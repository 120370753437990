.container {
  width: 65%;
  margin: auto;
  margin-top: 2rem;
  margin-bottom: 3rem;
  padding: 2rem;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 10px;
}
.form-label {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 1.2rem;
}
#submitBtn {
  margin-top: 2.5rem;
}
.uploadNewsMainContainer {
  /* border: 1px solid red; */
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-right: 8px;
  position: absolute;
}
.uploadMainBox {
  width: 28%;
}
#uploadBox-1 {
  display: flex;
  align-items: center;
  position: relative;
  top: -130px;
  left: 320px;
}
.uploadedNews {
  width: 90%;
  margin: auto;
  margin-bottom: 1rem;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  padding: 2px;
}
#selectedNewsArea {
  margin-top: 2.2rem;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  border-radius: 10px;
}

/* uploaded image preview section css */

/* .image-preview-container {
  border: 1px solid red;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
  margin-top: 4rem;
  margin-bottom: 4rem;
  padding: 1rem;
} */
/* .image-preview-container > div {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
} */

.add-news-section {
  display: flex;
  align-items: center;
  margin-top: 0.6rem;
}

.remove-and-image-preview {
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  /* border: 1px solid black; */
}
.uploaded-image {
  width: 50px;
  height: 20px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  /* border: 1px solid red; */
}
.page-name {
  text-align: center;
}
.add-page{
  margin-top: 0.6rem;
  background-color: aquamarine;
  border: 1px solid aquamarine;
  border-radius: 5px;
  padding: 0.3rem;
}
.add-page:hover{
  background-color: aquamarine;
}
