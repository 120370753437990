#dashboardContainer {
  /* border: 1px solid red; */
  background-color: rgb(236, 240, 250);
  padding: 1rem;
}

/* 1st Section */
#greetingBoxSection-1 {
  /* border: 1px solid black; */
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.greetingBoxLeftContent {
  /* border: 1px solid black; */
  line-height: 0.5;
}
.greetingBoxRightContent {
  /* border: 1px solid black; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
  line-height: 0.5;
}
.Ratings {
  /* border: 1px solid green; */
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Section 2nd */
#businessDetailSection-2 {
  /* border: 1px solid red; */
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
  margin-top: 2rem;
}
.businessDetailSectionCards {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 10px;
}
.businessDetailSectionCardsContent {
  display: flex;
  justify-content: space-between;
  padding: 1.5rem 1rem;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  background-image: linear-gradient(
    to left,
    rgba(1, 98, 232, 0.5) 0%,
    #0162e8 100%
  );
}
.businessDetailSectionCards > div > div:nth-child(2) {
  /* border: 1px solid white; */
  display: flex;
  justify-content: center;
  align-items: center;
}
.businessDetailSectionCardsAreaChart {
  /* border: 1px solid white; */
  width: 100%;
  background-image: linear-gradient(
    to left,
    rgba(1, 98, 232, 0.5) 0%,
    #0162e8 100%
  );
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

/* 3rd section */
#order-and-sale-section-3 {
  /* border: 1px solid black; */
  display: flex;
  justify-content: space-between;
  margin-top: 3rem;
}
.order-status {
  width: 55%;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 10px;
  background-color: white;
  padding: 1rem;
}
.sales-revenue {
  width: 40%;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 10px;
  background-color: white;
  padding: 1rem;
}

/* 4th section */
#main-box-section-4 {
  display: flex;
  justify-content: space-between;
  margin-top: 3rem;
}
.cards-section-4 {
  width: 45%;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 10px;
  background-color: white;
  padding: 1rem;
}
.customer-details {
  border-bottom: 1px solid gray;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.customer-details > div:nth-child(1) {
  /* border: 1px solid black; */
  display: flex;
  align-items: center;
  gap: 0.7rem;
}
.customer-details > div > div:nth-child(1) {
  width: 40px;
  border-radius: 50%;
}
.customer-details > div > div > img {
  border-radius: 50%;
}
.customer-details > div > div:nth-child(2) {
  padding-top: 8px;
}
.lineChartBox {
  /* border: 1px solid red; */
  width: 250px;
}
.apex-pie-chart-section {
  /* border: 1px solid red; */
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.apex-pie-chart-section > div:nth-child(1) {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 10px;
  background-color: white;
  padding: 1rem;
  height: 65%;
}
.apex-pie-chart-section > div:nth-child(2) {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 10px;
  background-color: white;
  padding: 1rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 25%;
}
