#mapMainContainer{
    position: relative;
    overflow: hidden;
    background-color: rgb(255, 255, 255);
}
    
#mapMainContainer>svg{
   margin-left: 1.5rem;
}

.jqvmap-region{
    cursor: pointer;
}
.country-name-popup{
    background-color: black;
    color: white;
    width: 14%;
    text-align: center;
    font-size: 12px;
}